
import { Options, Vue } from "vue-property-decorator";
import canjiandanwei from "../components/canjiandanwei.vue";
import usermanger from "../components/usermanger.vue";
import areatype from "../components/areatype.vue";
import * as api from "@/api/qualitycheck";
@Options({
  components: {
    canjiandanwei,
    usermanger,
    areatype,
  },
})
export default class extends Vue {
  private value = "";
  //参见单位id
  private deptId = "";
  //负责人id
  private userId = "";
  // 区域类型id
  private areaId = "";
  private areaIdscolumns: any[] = [];
  protected selectCompany(val: any) {
    this.deptId = val;
  }
  mounted() {
    api
      .projectArealist({
        projectId: this.$store.state.project.projectinfo.projectId,
      })
      .then((res: any) => {
        if (res.code === 0) {
          this.areaIdscolumns = res.data;
        } else {
          this.$toast.fail(res.msg);
        }
      });
  }

  protected selectUserManger(val: any) {
    this.userId = val;
  }

  protected selectArea(val: any) {
    this.areaId = val;
  }

  protected onSubmit() {
    let nameArray: any[] = [];
    this.areaIdscolumns.map((item) => {
      nameArray.push(item.name);
    });
    var index = nameArray.findIndex((item) => {
      if (item == this.value) {
        return true;
      }
    });
    if (index >= 0) {
      this.$toast.success({
        duration: 1500,
        message: "该区域已经创建，请从列表中选择",
        forbidClick: true,
      });
    } else {
      let pid = this.$store.state.project.projectinfo.projectId;
      api
        .projectAreaSave(
          {
            name: this.value,
            projectId: this.$store.state.project.projectinfo.projectId,
            companyId: this.deptId,
            changePersonId: this.userId,
            typeId: this.areaId,
          },
          pid
        )
        .then((res: any) => {
          if (res.code === 0) {
            this.$toast.success({
              message: res.msg,
              forbidClick: true,
              onClose: () => {
                this.$router.push("/app/qualitycheckadd");
              },
            });
          } else {
            this.$toast.fail(res.msg);
          }
        });
    }
  }
}
